.recurring-space-card {
    height: auto;
    border-radius: 5px;
    border: 1px solid #DFE2E7;
}

.card-selected {
    border: 3px solid #4893CF;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.content-card {
    background-color: #DFE2E7;
    border-radius: 0px;
}

.space-info {
    border: 1px solid #DFE2E7;
    padding-bottom: 10px;
}

.headings {
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.availability-text {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    align-self: center;
}

.occurences {
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.card-image {
    width: 100%;
    object-fit: cover;
    height: 133px;
    z-index: 2;
}

.recurring-icon {
    img {
        filter: brightness(3) invert(1);
        height: 18px;
        width: 18px;
        padding: 0 12px 0 5px;
    }
}

.best-fit-label {
    position: absolute;
    background-color: #0288D1;
    border-radius: 16px;
    padding: 5px 20px;
    margin: 15px 15px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
}