.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin-top: 223px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    color: var(--ui-text);
    font-size: 26px;
    line-height: 33px;
  }
  .subtitle {
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
    color: var(--ui-text-alternate);
  }

  .forgotPassword{
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 24px;
    color: var(--ui-text-alternate);

  }

  a:link{
    text-decoration: none !important;
  }
  


  .wrapper {
    .e-input-group {
      padding-top: 15px;
      // border-bottom: 2px solid #4893cf;
      border-bottom: 2px solid var(--ui-line-brake) !important;
      font-size: 16px;
      padding-left: 20px;
      flex-direction: left;
      width: 300px;
    }
    .e-input-group:hover {
      border-bottom: 2px solid var(--ui-primary) !important;
    }

    i {
      font-size: 20px;
      margin-top: 15px;
      color: var(--ui-line-break);
      position: absolute;
    }
    .e-input {
      margin: 8px 30px;
      width: 100vw;
      height: 26px;
    }
  }
  .continue-button {
    background: var(--ui-primary);
    border-radius: 8px;
    height: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--ui-light-text);
    border: none;
    cursor: pointer;
    margin-top: 100px;
    width: 300px;
  }

 .submitrequest-button {
  background: var(--ui-primary);
    border-radius: 8px;
    height: 50px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--ui-light-text);
    border: none;
    cursor: pointer;
    margin-top: 100px;
    width: 300px;

  }

  // /* To change the border-color of the textbox */

  /* To change the border-color of the floating-label textbox */

  /*common class for sf icon */
  .e-icons {
    color: #000;
    font-size: 26px;
  }
  /* class for sf email icon */
  .e-mail:before {
    content: "\e240";
  }

  /* error flags */
  .text-danger {
    color: red;
    text-align: left;
    font-size: 16px;
    margin-top: 6px;
    text-align: center;
    width: 300px;
  }
}
.tabMenu:focus{
  //border: none  !important;
  outline: none;
  border-bottom: 1px solid  !important;
  border-color: var(--ui-primary);
  padding-bottom: 30px;
}
.tab-btn{
  height: 80px;
  border: none;
  border-bottom: 2px solid;
  font-size:16px;
  border-color: var(--ui-secondary);
}