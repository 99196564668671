.page-container {
  .submenu-wrapper {
    width: 100%;
    background: var(--ui-background-alternate);
  }
  .submenu {
    width: calc(100vw - 250px);
    background: var(--ui-background-alternate);
    height: 45px;
    align-items: center;
    display: flex;
    list-style: none;
    text-decoration: none;
    margin-top: -2px;
    .menuitem {
      // margin-left: 50px;
      font-weight: bolder;
      font-size: 16px;
      // line-height: 20px;
      text-align: center;
      color:var(--ui-secondary);
      // border-bottom: 3px solid var(--ui-background-alternate);
      width: 170px !important;
      padding-top: 34px;
      padding-bottom: 32px;
      text-decoration: none;
      white-space: nowrap;
      padding-left:20px;
      padding-right:20px;
      background-color: var(--ui-mid-tone);
    }
    .active {
      // border-bottom: 3px solid;
      // border-color:var(--ui-primary);
      //color:var(--ui-primary) !important;
      background-color: var(--ui-background);
      font-weight: bolder;
    }
    .active-link{
      text-decoration: none;
      color:var(--ui-primary) !important;
    }
    .inactive-link{
      text-decoration: none;
      color:var(--ui-text) !important;
      // background-color: var(--ui-mid-tone);
      font-weight: bolder;
    }
  }

.content {
  padding: 35px 35px 25px 35px;

  height: calc(100% - 201px);
  position: relative;
  transition: margin-left 300ms;
  overflow-x: hidden;
  overflow-y: auto;

  // position:relative;
  // transition: margin-left 300ms;
}
  .rightPanel {
    width: calc(100vw - 225px);
    height:100vh;
    .page-title {
      color: var(--ui-text);
    }
    .panelWidth {
      width: calc(100vw - 225px);
    }
    .breadcrumbs { 
      margin-bottom: 11px;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color:var(--ui-text) !important;
      text-decoration: none;
    }

    .rightPanel-main-content {
      padding: 19px 25px 16px 25px;
    }

  }
  .header-Primary {
    width: 100vw;
    height: 74px;
    background: var(--ui-background-alternate);
  }
}

.rightPanel-main-button-group {
  padding: 10px 61px 25px 61px;
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex;
  .navbar-title {
    font-style: normal;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: var(--ui-secondary);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 26px;
    font-weight: 600;
    // height: 64px;
  }
}
.table-panel {
  background-color: var(--ui-background-alternate);
  position: relative;
  border-radius: 10px;
  padding: 16px;
}

.rightPanel-main-content-chart {
  padding: 0px 35px 0px 35px;
  margin-top: 35px;
  margin-bottom: 35px;  
  overflow: auto;
  height: calc(100vh - 212px);
}


.chart-panel {
  position: relative;
  // margin-left: 16px;
  // margin-right: 16px;
  border-radius: 10px;
  width: min-content;
  display: flex;
  flex-direction: column;
}

.chart-panel-section1 {
  background: var(--ui-background-alternate);
  padding: 16px;
  border-radius: 5px;
}

.chart-panel-section2 {
  display: flex;
  margin-top: 35px;
}

.chart-panel-info {
  margin-bottom: 8px;
}

.chart-panel-heading {
  position: relative;
}

.chart-panel-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color:  var(--ui-text);
}

.chart-panel-icon img {
  height: 26px;
  width: 26px;
  margin-bottom: 5px;
}

.chart-panel-header {
  display: inline-block;
  position: relative;
  margin-left: 12px;
  font-family: Source Sans Pro;
  font-weight: 600;
  font-style: normal;
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 0;
  color:  var(--ui-text);
}

.chart-panel-description { 
  font-family: Source Sans Pro;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  color: #828282;
  height: 31px;  
}

.table-panel-info {
  margin-bottom: 8px;
}

.table-panel-heading {
  position: relative;
}

.table-panel-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color:  var(--ui-text);
}

.table-panel-icon img {
  height: 26px;
  width: 26px;
  margin-bottom: 5px;
}

.table-panel-breadcrumbs {
  position: relative;
  display: inline;
  left: 16px;
  color:  var(--ui-text);
}


.table-panel-header {
  display: inline-block;
  position: relative;
  margin-left: 12px;
  font-family: Source Sans Pro;
  font-weight: 600;
  font-style: normal;
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 0;
  color:  var(--ui-text);
}

.table-panel-description { 
  font-size: 16px;
  color: var(--ui-text);
  margin-bottom: 1rem;
  margin-top: 8px
}

.table-panel-content {
  z-index: 99;
  position: absolute;
  right: 16px; 
}
.table-panel-content button {
  cursor: pointer;
  height: 40px;
  width: 125px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  // background-color: var(--ui-primary);
  border: 1px solid;
  font-family: Source Sans Pro;
  font-weight: 600; 
  font-style: normal;
  font-size: 16px;
  // color: var(--ui-light-tex);
}

.table-panel-content-datepicker {
  position: absolute !important;
  left: 420px; 
  height: 40px;
}

.table-panel-content-datepicker .react-datepicker__input-container {
  height: 40px !important;
}

.table-panel-content-datepicker input{
  height: 40px !important;
}

.page-title h1{
  text-transform: capitalize;
  margin:0;
}
.text-desabled {
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--ui-text-alternate);
  margin-bottom: 7px;
}

.border {
  border: 1px solid;
  border-color: var(--ui-secondary) !important;
}
.button-filter{
  width: 100px !important;
  height: 40px !important;
}

.tabledata {
  margin : 20px;
  font-weight: bolder;
  font-size: 16px;
  text-align: center;
}


/* Submenu-dropdown CSS starts */
.dropbtn1 {
  color: var(--ui-text);
  margin-bottom: 4px;
  padding: 16px 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: Source Sans Pro;
  border: none;
  cursor: pointer;
  background:transparent;
}

.dropdown1 {
  position: relative;
  display: inline-block;
  // right: 20px;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: var(--ui-background-alternate);
  min-width: 200px;
  z-index: 999;
 
}

.dropdown-content1 a {
  color: black;
  padding: 0px 0px;
  text-decoration: none;
  display: block;
  white-space:nowrap;
}
#receiver li{
  border-bottom: 1px solid var(--ui-line-brake);
  width:100%;
}
#receiver{
  padding: 0;
}
.dropdown-content1 a:hover {background-color:var(--ui-primary);}

.dropdown1:hover .dropdown-content1 {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  margin-top: -2px !important;
  background-color: transparent;
}
#teleporter { 
  width: 85%;
  // height : 58px;
  // width: calc(100vw - 380px);
  // margin-right: 100px;
  overflow: hidden;
  height: 100%;
  display:inline;
  box-sizing: border-box;
  padding: 0;
  margin-left:47px;
  display: inline-box;
}

#teleporter li {
  padding: 16px 3px;
  width: 150px !important;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  // line-height:24px;
  height: 45px;
  font-size: 14px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}

#teleporter li,#receiver li {
  float: left;
  box-sizing: border-box;
  list-style: none;
  
}
#receiver li{
  display:block;
  padding: 10px 10px;
  margin-left: 0;
}

#receiver li a{
background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    // font-weight: 600;
    font-family: Source Sans Pro;
    color: var(--ui-text);
    white-space: nowrap;
}

#receiver li a:hover{
      color: var(--ui-primary);
  }
  /* Submenu-dropdown CSS Ends */

.nopadding {
  padding: 0px !important;
}

.button-center {
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.navbar-title-margin{
  margin-left: 50px;
  margin-top: 16px;
}

.securityIcons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: 30px;
  justify-content: center;
  align-items: center;

  .securityIcon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 60px; 
    margin-bottom: 16px;
    margin-right: 10px;

    .securityIcon-title{
      height: 19px;
      font-size: 14;
      font-family: "Source Sans Pro";
      font-weight: normal;
    }

    .securityIcon-image{
      width: 20px;
      height: 20px;
    }
  }
}
