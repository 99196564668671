.table-white{
  background-color: var(--ui-background-alternate);
}
.table-head {
  height: 50px;
  line-height: 60px;
  font-family: Source Sans Pro;
  font-style: normal;
  color: var(--ui-text-alternate);
  background: var(--ui-background-alternate);
  border-bottom: 1px solid var(--ui-mid-tone);

  .th {
    padding: 0px 25px;
    color: var(--ui-text-alternate);
    text-align: left;
    font-size: 16px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
  }
}
.tablerow-dashboard-scroll {
  height: calc(100vh - 390px);
  -webkit-overflow-y: auto;
  overflow-y: auto;
  display: block;
}

.tablerow-dashboard-scroll-button {
  height: calc(100vh - 445px);
  -webkit-overflow-y: auto;
  overflow-y: auto;
  display: block;
}

.tablerow-dashboard-scroll::-webkit-scrollbar-track {
  background: var(--ui-background-alternate);
}

.tablerow-dashboard-scroll::-webkit-scrollbar-thumb {
  background-color: var(--scroll-track);
}
.tablerow-scroll {
  //max-height: 458px;
  overflow-y: scroll;
  display: block;
}

.tablerow-scroll::-webkit-scrollbar-track {
  background: var(--ui-background-alternate);
}

.tablerow-scroll::-webkit-scrollbar-thumb {
  background-color: var(--scroll-track);
}

.table-row {
  height: 50px;
  border-bottom: 1px solid var(--ui-mid-tone);
  line-height: 70px;
  color: var(--ui-text);
  background: var(--ui-background-alternate);

  .td {
    padding: 0px 25px;
    font-weight: bold;
    font-size: 16px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
  }
  .button-outline {
    display: inline-block;
    height: 50px;
    width: 148px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    font-family: Source Sans Pro;
    border: 1.5px solid var(--ui-text); 
    background: var(--ui-mid-tone);
    cursor: pointer;
    color: var(--ui-text) !important;
  }
}
.col-name{
  width:33%;
  text-overflow:ellipsis;
  overflow:hidden;
}

.button-right{
  float:right;
}
.col-300 {
  width: 300px;
}
.col-100 {
  width: 100px;
}