@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";

@import "./styles/css/bootstrap-css-only/css/bootstrap.min.css";
@import "./styles/css/mdbreact/dist/css/mdb.css";
@import "./styles/css/@fortawesome/fontawesome-free/css/all.min.css";

:root {
  --ui-primary             : #4893CF;
  --ui-secondary           : #2A3F49;
  --ui-accent              : #EAAB1C;
  --ui-text                : #263238;
  --ui-error               : #F44336;
  --ui-success             : #00C853;
  --ui-warn                : #FFC400;
  --ui-background-alternate: #FFFFFF;
  --ui-background-topbar   : #2A3F49;
  --ui-background          : #F2F3F4;
  --ui-mid-tone            : #DCE1E5;
  --ui-text-alternate      : #7A7B82;
  --ui-line-break          : #DFE2E7;
  --ui-light-text          : #FFFFFF;
  --ui-error-pastel        : #FFBBBF;
  --ui-warn-pastel         : #FFF3CC;
  --ui-success-pastel      : #CCF4DD;
  --ui-primary-pastel      : #DAE9F5;
  --ui-cleaning            : #9B59B6;
  --ui-cleaning-pastel     : #E1CEEA;
  --ui-error-highlight     : #FFDDDF;
  --ui-warn-highlight      : #FFF9E5;
  --ui-success-highlight   : #E5F9EE;
  --color-card             : #1D2231;
  --text-grey              : hsl(215, 14%, 57%);
  --shadow                 : rgba(38, 50, 56, 0.1);
  --scroll-track           : #ccc;
  --ui-graph0              : #2962FF;
  --ui-graph1              : #00C853;
  --ui-graph2              : #FF7913;
  --ui-graph3              : #37474F;
  --ui-graph4              : #ECF0F1;
  --ui-graph5              : #9B59B6;
  --ui-graph6              : #FFC400;
  --ui-graph7              : #1C957B;
  --ui-graph8              : #F44336;
  --ui-gradient            :#FF0000;
  --ui-linear-gradient     :linear-gradient(180deg, #00FF00 0%, #FF0000 100%);
  --ui-core                :#F0EEE9;
}

:root[data-theme="dark"] {
  --ui-primary             : #2D9CFC;
  --ui-secondary           : #181A1E;
  --ui-accent              : #EAAB1C;
  --ui-text                : #FFFFFF;
  --ui-error               : #FF5660;
  --ui-success             : #39D67A;
  --ui-warn                : #FFDD6A;
  --ui-background-alternate: #26292E;
  --ui-background-topbar   : #2A3F49;
  --ui-background          : #1E2025;
  --ui-mid-tone            : #393D44;
  --ui-text-alternate      : #7E8183;
  --ui-line-break          : #141414;
  --ui-light-text          : #FFFFFF;
  --ui-error-pastel        : #FFBBBF;
  --ui-warn-pastel         : #FFF3CC;
  --ui-success-pastel      : #CCF4DD;
  --ui-primary-pastel      : #DAE9F5;
  --ui-cleaning            : #9B59B6;
  --ui-cleaning-pastel     : #E1CEEA;
  --ui-error-highlight     : #473036;
  --ui-warn-highlight      : #474437;
  --ui-success-highlight   : #294339;
  --shadow                 : rgba(246, 250, 252, 0.1);
  --scroll-track           : #999;
  --ui-graph0              : #2962FF;
  --ui-graph1              : #00C853;
  --ui-graph2              : #FF7913;
  --ui-graph3              : #37474F;
  --ui-graph4              : #ECF0F1;
  --ui-graph5              : #9B59B6;
  --ui-graph6              : #FFC400;
  --ui-graph7              : #1C957B;
  --ui-graph8              : #F44336;
  --ui-gradient            :#FF0000;
  --ui-linear-gradient     :linear-gradient(180deg, #00FF00 0%, #FF0000 100%);
  --ui-core                :#3F4042;
}

iframe[style *= "2147483647"] {
    display: none;
}


.jc-ai-center {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.js-space-arround {
  justify-content: space-between;
}

.al-c {
  align-items: center;
}

.m-0 {
  margin: 0px !important;
}
.m-24{
  margin:24px !important;
}

.p-0 {
  padding: 0px !important; 
}
.p-10{
  padding:10px !important;
}
.p-20{
  padding:20px !important;
}
.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 10px 45px 10px 45px;
}

.p-55 {
  padding-top   : 35px;
  padding-left  : 35px;
  padding-right : 35px;
  padding-bottom: 25px;
}
.pointer{
  cursor:pointer !important;
}
.line-height-1{
  line-height: 1 !important;
}
.height-30{
height:30px;
}
.ui-text-alternate{
  color:var(--ui-text-alternate) !important;
}
.labels-sm{
  font-size:14px;
  color:var(--ui-text-alternate);
}
.m-title {
  margin-left: 50px;
  /* margin-top : 16px; */
}

.page-context {
  margin-left: 0px;
  height     : 31px;
  font-size  : 14px;
  color      : var(--ui-text-alternate);
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.px-0{
  padding-left:0px !important;
  padding-right: 0px !important;
}
.px-20{
  padding-left:20px !important;
  padding-right: 20px !important;
}
.pl-24{
  padding-left:24px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-10{
  margin-right: 10px;
}
.mr-2 {
  margin-right: 16px !important;
}

.mr-30 {
  margin-right: 30px !important;
}
.mr-50{
  margin-right: 50px;
}
.ml-2 {
  margin-left: 16px !important;
}
.ml-24{
  margin-left:24px !important;
}
.mt-24{
  margin-top:24px !important;
}
.mt-90{
  margin-top:90px !important;
}
.mt-0{
  margin-top:0px !important;
}
.ml-4 {
  margin-left: 32px !important;
}

.mt-2 {
  margin-top: 15px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-3px{
  margin-bottom:3px !important;
}
.mb-6px{
  margin-bottom:6px !important;
}
.my-6px{
  margin-top:6px;
  margin-bottom:6px;
}
.mb-32{
  margin-bottom:32px !important;
}
.mb-16{
  margin-bottom:16px !important;
}
.pl-2 {
  padding-left: 34px !important;
}
.mx-16{
  margin-left:16px;
  margin-right: 16px;
}
.pr-2 {
  padding-right: 34px !important;
}

.mt-2 {
  margin-top: 34px !important;
}
.mt-16{
  margin-top: 16px !important;
}
.mt-1 {
  margin-top: 17px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.row {
  display: flex;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
  position     : relative;
  width        : 100%;
  padding-right: 10px;
  padding-left : 10px;
}

.catering-form-msg-field {
  padding-left : 0px !important;
  margin-bottom: 15px !important;
}


.col-1 {
  width: calc(16.66% - 20px);
}

.col-3 {
  width: calc(25% - 20px);
}

.col-200 {
  width: 200px;
}

.col-4 {
  width: calc(33.33% - 20px);
}

.col-5 {
  width: calc(41.66% - 20px);
}

.col-6 {
  width: calc(50% - 20px);
}

.col-7 {
  width: calc(58.33% - 20px);
}

.col-8 {
  width: calc(66.66% - 20px);
}

.col-12 {
  width: calc(100% - 20px);
}

.card {
  /* height       : calc(100vh - 373px); */
  /* overflow     : auto; */
  background      : var(--ui-background-alternate);
  border-radius   : 7px;
  display         : block;
  /* min-height   : 536px;
  height          : 537px; */
  /* box-shadow   : 0px 2px 7px rgba(38, 50, 56, 0.1); */
}

.status-card {
  background   : var(--ui-background-alternate);
  border-radius: 4px;
  /* min-height: 236px; 
  height       : 236px;*/
  box-shadow   : 0px 2px 7px rgba(38, 50, 56, 0.1);
}

/* ::-webkit-scrollbar{
	width:10px
} */
.vh-3 {
  height  : calc(100vh - 377px);
  overflow: auto;
}

.vh-4 {
  height  : calc(100vh - 455px);
  overflow: auto;
}

.col-2 {
  width        : 20%;
  border-radius: 4px;
}

.col-56 {
  width        : 56%;
  border-radius: 4px;
}

.col-46 {
  width        : 46%;
  border-radius: 4px;
}

.text-align-r {
  text-align: right;
}
.text-align-center{
  text-align:center !important;
}
.card col-12 {
  border-collapse: collapse;
}

button{
  font-weight: bold !important;
  font-family: Source Sans Pro;
}

.button-outline {
  display      : inline-block;
  height       : 40px;
  width        : 148px;
  padding      : 0.375rem 0.75rem;
  line-height  : 1.5;
  border-radius: 8px;
  border       : none;
  font-size    : 1rem;
  font-weight  : 600;
  font-family  : Source Sans Pro;
  border       : 1.5px solid var(--ui-text);
  background   : var(--ui-mid-tone);
  cursor       : pointer;
  color        : var(--ui-text) !important;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-footer .button-outline,.modal-footer .button-primary{
  height:50px !important;
}
.button-primary {
  display         : inline-block;
  height          : 40px;
  width           : 148px;
  padding         : 0.35rem 0.75rem;
  line-height     : 1.2;
  border-radius   : 8px;
  border          : none;
  font-size       : 1rem;
  font-weight     : 600;
  font-family     : Source Sans Pro;
  color           : var(--ui-light-text);
  background-color: var(--ui-primary);
  cursor          : pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-secondary {
  display         : inline-block;
  height          : 40px;
  width           : 125px;
  padding         : 0.35rem 0.75rem;
  line-height     : 1.2;
  border-radius   : 8px;
  border          : 1px solid var(--ui-secondary);
  font-size       : 1rem;
  font-weight     : 600;
  font-family     : Source Sans Pro;
  color           : var(--ui-text-alternate);
  background-color: transparent;
  cursor          : pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-secondary:disabled {
  background-color: var(--ui-mid-tone);
  cursor          : default;
}

.button-tertiary {
  display         : inline-block;
  height          : 40px;
  width           : 125px;
  padding         : 0.35rem 0.75rem;
  border-radius   : 8px;
  border          : 1.5px solid var(--ui-text-alternate);
  font-size       : 1rem;
  font-weight     : 600;
  font-family     : Source Sans Pro;
  color           : var(--ui-text-alternate);
  background-color: transparent !important;
  cursor          : pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-primary-disable {
  display         : inline-block;
  height          : 40px;
  width           : 125px;
  padding         : 0.35rem 0.75rem;
  line-height     : 1.2;
  border-radius   : 8px;
  border          : none;
  font-size       : 1rem;
  font-weight     : 600;
  font-family     : Source Sans Pro;
  color           : var(--ui-text-alternate);
  background-color: var(--ui-mid-tone);
  cursor          : default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-secondary-disable {
  display         : inline-block;
  height          : 40px;
  width           : 125px;
  padding         : 0.35rem 0.75rem;
  line-height     : 1.2;
  border-radius   : 8px;
  border          : 1px solid var(--ui-secondary);
  font-size       : 1rem;
  font-weight     : 600;
  font-family     : Source Sans Pro;
  color           : var(--ui-text-alternate);
  background-color: var(--ui-mid-tone);
  cursor          : default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-tertiary:disabled {
  background-color: var(--ui-mid-tone) !important;
  cursor          : default;
}

.button-tertiary-disable {
  display         : inline-block;
  height          : 40px;
  width           : 140px;
  padding         : 0.35rem 0.75rem;
  border-radius   : 8px;
  border          : 1.5px solid var(--ui-text-alternate);
  font-size       : 1rem;
  font-weight     : 600;
  font-family     : Source Sans Pro;
  color           : var(--ui-text-alternate);
  background-color: var(--ui-mid-tone) !important;
  cursor          : default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons-group {
  display        : flex;
  justify-content: space-between;
}

.space-between {
  display        : flex;
  justify-content: space-between;
}

.text-color {
  --ui-warn: #ffc400;
}

.table-body {
  background   : var(--ui-background-alternate);
  border-radius: 4px;
  box-shadow   : 0px 2px 7px rgba(38, 50, 56, 0.1);
  /* height    : 100%; */
}

.radio-button {
  padding-bottom: 21px;
  margin-left   : 52px;
}

.spaces-col-400 {
  width: min(400px, max(200px, 20%));
}

.spaces-col-300 {
  width: min(300px, max(150px, 20%));
}

.spaces-col-200 {
  width: min(200px, max(100px, 10%));
}

.spaces-col-150 {
  width: min(150px, max(100px, 10%));
}

.spaces-col-100 {
  width: min(100px, max(100px, 10%));
}

.spaces-col-text {
  text-align   : left;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.spaces-col-number {
  color        : var(--ui-text);
  font-weight  : bold;
  font-size    : 16px;
  text-align   : right;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.spaces-col-number-th {
  color        : var(--ui-text-alternate);
  font-size    : 16px;
  text-align   : right;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.col-button {
  /* min-width: 200px; */
  width      : max(200px, 33%);
  /*max-width:250px;*/
}

.card-scroller {
  /* overflow-y        : auto; */
  /* -webkit-overflow-y: auto; */
  display: block;
}

.card-scroller::-webkit-scrollbar-track {
  background: var(--ui-background-alternate);
}

.card-scroller::-webkit-scrollbar-thumb {
  background-color: var(--scroll-track);
}

.scrollbarShow {
  /* overflow : scroll */
}

.topbardiv {
  background-color: var(--ui-background-topbar);
  height          : 64px;
  display         : flex;
  align-items     : center;
}

.topbarlogo {
  padding-left  : 47px;
  padding-top   : 8px;
  padding-bottom: 8px;
}

.newCheckBox {
  display         : block;
  position        : relative;
  /* padding-left : 45px; */
   /* margin-top: 5px;  */
  cursor          : pointer;
  font-size       : 20px;
}

.checkBoxHide {
  visibility: hidden;
}

.geekmark {
  position           : absolute;
  top                : 0;
  left               : 0;
  height             : 20px;
  width              : 20px;
  background-color   : var(--ui-background-alternate);
  border             : 2px solid;
  border-color       : var(--ui-mid-tone);
  border-radius      : 5px;
  margin-top         : 9px;
  /* background      : var(--ui-mid-tone); */
  border             : 0.5px solid var(--ui-text-alternate);
}

.newCheckBox:hover input~.geekmark {
  /* background-color: var(--ui-background-alternate); */
}

.newCheckBox input:active~.geekmark {
  background-color: var(--ui-text-alternate);
}

.newCheckBox input:checked~.geekmark {
  background-color: var(--ui-primary);
}

.geekmark:after {
  content : "";
  position: absolute;
  display : none;
}

.newCheckBox input:checked~.geekmark:after {
  display: block;
}

.newCheckBox .checkBoxHide:disabled~.geekmark {
  background: var(--ui-mid-tone);
}

.newCheckBox .geekmark:after {
  left             : 5px;
  bottom           : 4px;
  width            : 6px;
  height           : 12px;
  border           : solid white;
  border-width     : 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform    : rotate(45deg);
  transform        : rotate(45deg);
}

.space-modal {
  overflow: auto;
}
.modal-content {
  /* background-color: var(--ui-secondary) !important;
  color           : var(--ui-light-text); */
}
/* .modal-attendees .modal-dialog{
  
} */
.modal-attendees .modal-dialog .modal-content {
  width:636px !important;
  border-radius:10px;
  background-color: var(--ui-background-alternate) !important;
  color           : var(--ui-text );
  /* background-color: var(--ui-secondary) !important;
  color           : var(--ui-light-text); */
}
.modal-dialog .modal-content {
  width:636px !important;
  border-radius:10px !important;
  background-color: var(--ui-background-alternate) !important;
  color           : var(--ui-text ) !important;
}
.modal-dialog{
  max-width:636px !important;
}
.modal-attendees .modal-dialog{
  max-width:636px !important;
}
.lable-modal{
  font-size: 16px;
  line-height: 20px;
  padding: 10px 10px;
  color:var(--ui-text);
}
.lable-modal-bold{
  font-size: 16px;
  line-height: 20px;
}
.my-28{
  margin: 28px 0px;
}
.my-8{
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-3px{
  margin-top:3px !important;
  margin-bottom:3px !important;
}
.my-9{
  margin-top: 9px;
  margin-bottom: 9px;
}
.px-0{
  padding-left:0px !important;
  padding-right: 0px !important;
}
.px-58{
  padding: 0px 58px !important;
}
.px-10{
  padding-left:10px;
  padding-right:10px;
}
.pt-10{
  padding-top:10px !important;
}
.ml-10{
  margin-left:10px !important;
}
.mr-10{
  margin-right: 10px !important;
}
.ml-18{
  margin-left:18px;
}
.checkbox-sm{
  width:20px !important;
}
.bg-secondary-light{
  background-color:var(--ui-background);
}
.btn-disabled{
  background:var(--ui-text-alternate);
  border: 1.5px solid  var(--ui-text-alternate);
  color:var(--ui-background-alternate);
  border-radius: 8px;
  height: 50px;
  text-transform: capitalize;
}
.btn-disabled:hover{
  background:var(--ui-text-alternate);
  color:var(--ui-background-alternate);
}

.btn-image
{
    padding: 0;
    height: 38px;
    cursor: pointer;
}

    .btn-image.btn-active
    {
        background-color: var(--shadow);
    }

.inputBox-border{
  border: 1px solid var(--ui-text-alternate);
  border-radius: 5px;
  margin-right:0px !important;
  margin-top: 6px;
  height: 50px;
}
.inputBox-border:focus{
  box-shadow: 0 0 0 0.2rem rgb(73 80 87 / 25%) m !important;
}
.modal-heading{
  font-size: 25px;
  color:var(---ui-text);
}

.subheading{
  font-size: 16px;
  color: var(--ui-text)
}
.date-label{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Light/Text */
  color: var(--ui-text);
}
.modal-attendees .modal-header,.modal-dialog .modal-content .modal-header{
  border-radius: 10px 10px 0px 0px !important;
  height:89px;
  box-sizing: border-box;
  background-color: var(--ui-background);
  padding: 0px 58px !important
}
.modal-header{
  border-radius: 10px 10px 0px 0px !important;
  height:89px !important;
  box-sizing: border-box !important;
}
.modal-header label,.modal-header .modal-title{
  font-size: 25px;
  color: var(--ui-text);
}
.modal-body{
  padding: 58px !important;
}
.attendee-hr{
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 1px;
  border: none;
  background-color:var(--ui-line-break) !important;
}
.attendee-name{
  font-size: 16px;
  line-height: 20px;
  /* display: flex;
  align-items: center; */
  color: var(--ui-text-alternate);
}
.alternate-text .fa{
  padding-left: 5px;
}
.attendee-name .fa:hover{
  color:var(--ui-error);
  cursor: pointer;
}
.clear-attendees{
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color:var(--ui-text-alternate);
  cursor: pointer;
}
.modal-attendees .modal-footer,.modal-footer{
  border-top: 1px solid var(--ui-line-break) !important;
  z-index: 99;
  padding: 20px 0px;
  width: 100%;
  display: flex;
  justify-content: center !important;
  padding:20px 0px !important;
  /* padding:58px 0px; */
}
.modal-attendees-form{
  padding-left: 10px;
  margin: 6px 0px;
  width: calc(100% - 50px);
}
.modal-content .close {
  color: var(--ui-light-text);
  font-size: 2rem;
  cursor:pointer;
}
.modal-attendees .modal-dialog .modal-content .close {
  color: var(--ui-text);
}
.modal-content .close {
  color: var(--ui-text);
}
.modal-content .close:hover {
  color: var(--ui-secondary);
}

/* width */

.modal-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */

.modal-content::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.modal-content::-webkit-scrollbar-thumb {
  background   : var(--ui-background);
  border-radius: 10px;
}

/* Handle on hover */

.modal-content::-webkit-scrollbar-thumb:hover {
  background: var(--ui-background);
}

@media (min-width: 1300px) {
  .chart-height {
    /* max-height: 250px; */
    max-height: 160px;
    min-height: 160px;
  }

  .chartpie-height {
    max-height    : 75px;
    min-height    : 75px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1430px) {
  .chart-height {
    /* max-height: 250px; */
    max-height: 210px;
    min-height: 210px;
  }

  .chartpie-height {
    max-height    : 95px;
    min-height    : 95px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1520px) {
  .chart-height {
    /* min-height: 275px; */
    min-height: 270px;
    max-height: 270px;
  }

  .chartpie-height {
    min-height    : 120px;
    max-height    : 120px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1720px) {
  .chart-height {
    /* min-height: 325px; */
    min-height: 295px;
    max-height: 295px;
  }

  .chartpie-height {
    min-height    : 145px;
    max-height    : 145px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1921px) {
  .chart-height {
    /* min-height: 350px; */
    min-height: 380px;
    max-height: 380px;
  }

  .chartpie-height {
    min-height    : 170px;
    max-height    : 170px;
    padding-bottom: 15px;
  }
}


.textregular {
  text-transform: none !important;
}

.boderSoild {
  border       : 1px solid gray;
  margin-bottom: 2px;
  font-size    : 16px;
  overflow-y   : scroll;
  margin-right : 20px;
}

.nonePointer {
  /* pointer-events: none; */
  margin-left: 20px;
}

.scrolldiv {
  padding   : 35px 35px 25px 35px;
  height    : calc(100vh - 212px);
  width     : calc(100vw - 310px);
  overflow-y: scroll;
}

.statusbox {
  height: 15px;
  width : 15px;
}

/*=========================================================*/
.bg-image {
  /*background-image: url(images/bg-image.jpg);*/
  background-size : cover;
  height          : 100vh;
  background-color: rgba(255, 255, 255, 0.5);
}

.box {
  height          : 50%;
  width           : 30%;
  background-color: #ffffff;
  position        : absolute;
  top             : 50%;
  left            : 50%;
  transform       : translate(-50%, -50%);
}

.btn-align {
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.box-label {
  color     : #2c2c2c;
  font-size : 20px;
  position  : relative;
  top       : 20px;
  left      : 20px;
  text-align: center;
}

/*==================================================================*/
.redirectContainer {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  position       : absolute;
  top            : 50%;
  left           : 50%;
  transform      : translate(-50%, -50%);
}

.title {
  color      : var(--ui-secondary);
  font-size  : 26px;
  line-height: 33px;
  margin-bottom: 24px;
}

.subtitle {
  font-style   : normal;
  font-size    : 16px;
  line-height  : 20px;
  text-align   : center;
  margin-bottom: 24px;
  color        : var(--ui-text-alternate);
  width        : 300px;
}

.redirect-continue-button {
  background   : var(--ui-primary);
  border-radius: 8px;
  height       : 50px;
  font-style   : normal;
  font-weight  : 500;
  font-size    : 16px;
  line-height  : 22px;
  color        : var(--ui-light-text);
  border       : none;
  cursor       : pointer;
  width        : 300px;
}

.redirect-display-token {
  margin  : auto;
  width   : 15%;
  border  : 3px solid green;
  padding : 10px;
  overflow: auto;
  display : block;
}

.topchart {
  background-color   : var(--ui-background-alternate);
  /* padding         : 25px; */
  margin-bottom      : 35px;
}

.topchartheight {
  min-height: 400px;
  width     : min(960px, max(2200px, 100vw - 320px));
}

.donutchart {
  min-height         : 250px !important;
  /* max-height      : 250px !important; */
  width              : calc((100vw - 390px) / 3);
  min-width          : 320px;
  max-width          : 600px;
  background-color   : var(--ui-background-alternate);
  color              : var(--ui-text);
  border-radius      : 5px;
}

.donutchartSpace {
  /* min-height      : 250px !important;
  max-height      : 400px !important; */
  width        : calc((100vw - 390px) / 4);
  min-width    : 215px;
  max-width    : 600px;
  border-radius: 5px;
  min-height   : 25vw;
  cursor       : pointer;
}

.spacePanel {
  background-color: var(--ui-background-alternate);
  color           : var(--ui-text);
  padding         : 5px 0px 7px 0px;
  min-height      : calc((25vw - 40px)/3);
  margin-bottom   : 20px;
  border-radius   : 5px;
}

.recharts-wrapper svg> {
  width    : calc((100vw - 390px) / 4);
  min-width: 415px;
  max-width: 600px;
}

.chartcenter {
  margin        : auto;
  display       : table;
  padding-bottom: 20px;
}

.chartcenter .recharts-wrapper {
  cursor: pointer !important;
}

.donutchartmargin {
  margin-left: 35px;
}

.donutchartlegend {
  width      : 50%;
  float      : left;
  align-items: center;
  padding-top: 5px;
  word-break : break-word;

}

.donutchartlegendSpace {
  width     : 100%;
  background: var(--ui-background-alternate);
  overflow  : auto;
  max-height: calc(50vh - 200px);
  word-break: break-word;
}

.donutcharttitle {
  margin-left: 30px;
}

.donutcharttitlecenter {
  text-align   : center;
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
  padding      : 0px 10px 0px 10px;
}

.tableText {
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  overflow   : hidden;
}

.titleCenter {
  text-align: center;
  color     : var(--ui-text);
}

.logoModular {
  height     : 33px;
  margin-left: 18px;
  cursor     : context-menu;
}

.cardHeight {
  overflow     : auto;
  background   : var(--ui-background-alternate);
  border-radius: 4px;
  display      : block;
  box-shadow   : 0px 2px 7px rgb(38 50 56 / 10%);
}

.cardTopSpace {
  margin-top   : 35px;
  overflow     : auto;
  background   : var(--ui-background-alternate);
  border-radius: 4px;
  display      : block;
  box-shadow   : 0px 2px 7px rgb(38 50 56 / 10%);
}


button:focus {
  outline: none !important;
}

.charttable > tbody > tr {
  margin-bottom: 10px;
}

.charttable > tbody > tr > td:nth-child(1),
.charttable > tbody > tr > td:nth-child(3),
.charttable > tbody > tr > td:nth-child(4) {
  word-break: keep-all;
  vertical-align: baseline;
}

.fileTag {
  line-height: 3;
  padding: 0px !important;
}

.capital {
  text-transform: uppercase;
}

.table-panel img {
  width: 100%;
 max-height: 60%; 
 position: relative;
 overflow: scroll;
 
}

.back-btn-cont{
 flex-direction: row-reverse;
}
.back-btn-floors{
 position: absolute;
 bottom: 200px;
 right: 100px;
}
.modal-iframe{
  width: 99%;
  height: 98%;
  border: none;
}
.iframe-popup .modal-dialog{
  max-width: 1272px !important; 
  height: 600px !important;
}

.iframe-popup .modal-dialog .modal-content {
  width:1272px !important;
  border-radius:10px;
  background-color: var(--ui-background-alternate) !important;
  color           : var(--ui-text );
  height: inherit;
}

.iframe-popup .modal-header{
  border-radius: 10px 10px 0px 0px !important;
  height:89px;
  box-sizing: border-box;
}

.iframe-popup .modal-dialog .modal-content .close {
  color: var(--ui-secondary);
}
/* CSS changes by Nikhil */
.table td, .table th{
  vertical-align: middle !important;
}
.mb-8{
  margin-bottom: 8px !important;
}
.mb-10{
  margin-bottom:10px !important;
}
.ml-8{
  margin-left:8px !important;
}
.mt-8{
  margin-top: 8px !important;
}
.mt-20{
  margin-top: 20px !important;
}
.pt-0{
  padding-top: 0px !important;
}
.pb-4px{
  padding-bottom: 4px !important;
}
.pb-10{
  padding-bottom:10px !important;
}
.py-14{
  padding: 0px 14px;
}

.pb-14{
  padding-bottom: 14px;
}
.p-58{
  padding:58px !important;
}
.btn-right-aligned{
  margin-top: 5px;
  display: flex;
  justify-content: end;
}
.my-24{
  margin-top:24px !important;
  margin-bottom:24px !important;
}
.mr-24{
  margin-right:24px !important;
}
.mx-22{
  margin: 0px 22px 0px 22px !important;
}
.mx-10{
  margin-left:10px;
  margin-right:10px;
}
.border-box{
  box-sizing: border-box;
}
.w-90{
  width: 90%;;
}
.img-flex-tab{
  width: 318px;
  height: 119px !important;
  overflow: hidden;
  position: relative;
}
.filter-criteria-inputbox{
  width: 300px !important;
  height: 40px !important;
}

.img-flex-tab{
  width: 318px;
  height: 139px !important;
  overflow: hidden;
}
.filter-criteria-inputbox{
  width: 300px !important;
  height: 40px !important;
}

.round {
  position: relative;
}

.round label {
  background-color: var(--ui-background-alternate);
  border: 1px solid var(--scroll-track);
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 18px;
}

.round label:after {
  border: 2px solid var(--ui-background-alternate);
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--ui-success);
  border-color: var(--ui-success);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.disabled-labels{
  background-color: var(--ui-background);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 17px 8px;
}
.form-elements{
  padding: 10px 24px 4px 24px;
  width: 334px;
  max-height: calc(100vh - 220px) !important;
  overflow-y: auto;
}
.shadow-top{
  box-shadow: 0px -2px 7px rgba(38, 50, 56, 0.1);
}

body {
  margin     : 0;
  font-family: Source Sans Pro;
  font-weight: bold !important;
}
#floor-img svg{
  height: calc(100vh - 200px) !important;
}
#alignLables .row .form-input-box .form-input-box-label{
  width: 135px;
  font-size: 16px;
}
#alignLables .row .form-input-box .form-input-box-input{
  width: 135px;
  font-size: 16px;
  color: var(--ui-text);
  text-overflow: ellipsis;
}
.mb-13{
  margin-bottom: 13px;
}
.ml-28{
  margin-left: 28px
}
.overflow-ellipsis{
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
width:100%;
}
.d-flex{
  display:flex;
}
.text-right{
  text-align: right;
}
.text-decoration-none{
  text-decoration: none;
}
::placeholder{
  color: var(--ui-text-alternate);
}

.col-text{
  color: var(--ui-text) !important;
}
.headArrow{
  color: grey !important;
  font-size: 14px
}
.arrow-top{
  margin-top: -14px !important;
}

.react-switch-handle{
  background-color: var(--ui-background-alternate) !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  color: var(--ui-text) !important;
}

.headArrow{
  color: grey !important;
  font-size: 14px
}
.arrow-top{
  margin-top: -5px !important
}
.alignContent {
  margin-top: 15px !important
}

.ai-center{
  display: flex;
  align-items: center;
}

.tablePanel{
  background-color: var(--ui-background-alternate);
  position: relative;
  border-radius: 10px;
  padding: 16px;
}

option{
  font-weight: bold;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  background-color: #fff;
  padding: 10px;
  margin: 10px
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.item1 {
  grid-column: 1 / span 2;
}

.mr-8px{
  margin-right: 20px !important;
}

.df-jce-mt71{
  display: flex;
  justify-content: end;
  margin-top: 71px;
}

/* New Component css changes and classes  */

/* Modal Popup UI Classes */
.ibss-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 484px;
  background-color: var(--ui-background-alternate);
  box-shadow: 24px;
  height: auto;
  text-align: center;
  border-radius: 8px;
  max-height: calc(100vh - 40px);
  overflow: auto;
  /* margin: 24px 0px; */
}
.ibss-modal-header{
  padding: 16px 24px;
  background-color: var(--ui-background);
  border-radius: 8px 8px 0px 0px;
  text-align: start;
  align-items: center;
}
.ibss-modal-title{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  color: var(--ui-text);
}
.ibss-modal-text-md{
  font-family: 'Source Sans Pro';
  font-style: normal;
  /* font-weight: 400; */
  font-size: 16px;
  line-height: 161.2%;
  color:var(--ui-text);
}
.ibss-modal-text-sm{
  font-family: 'Source Sans Pro';
  font-style: normal;
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 161.2%;
  /* or 23px */
  text-align: center;
  color:var(--ui-text);
}
.ibss-text-light{
  color:var(--ui-text-alternate );
}
.modal-headerIco-lg{
  font-size: 28px;
  color:var(--ui-text);
  line-height: 16px;
  cursor: pointer;
}
.ibss-label-bold-md{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color:var(--iu-text);
}
.ibss-modal-dropdown{
  min-width: 100%;
  border: 1px solid var(--ui-mid-tone);
  border-radius: 5px;
}
.btn-block {
  display: block !important;
  width: 100% !important;
}
.btn-lg{
  height: 50px;
  font-size: 16px;
  line-height: 20px;
}
/* Margins used in components */
.mb-34{
  margin-bottom: 34px;
}
.my-16{
  margin-top:16px !important;
  margin-bottom:16px !important;
}
.my-6{
  margin-top:6px !important;
  margin-bottom:6px !important;
}
.mt-8{
  margin-top:8px;
}
.my-0{
  margin-top: 0px;
  margin-bottom: 0px;
}
.p-24{
  padding:24px !important;
}
.px-50{
  padding-left: 50px;
  padding-right: 50px; 
}
.w-100{
  width:100%;
}
.w-300{
  width: 300px !important;
}
.w-234{
  width:234px !important;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center !important;
}
/* background colors  */
.bg-white{
  background-color: var(--ui-background-alternate) !important;
}
/* FLex box classes */
.d-flex{
  display:flex !important;
}
.flex-wrap{
  flex-wrap: wrap;
}
.d-block{
  display:block;
}
.justify-content-between{
  justify-content: space-between !important;
}
.justify-content-center{
  justify-content: center !important;
}
.align-items-center{
  align-items: center !important;
}
.align-items-end{
  align-items: end !important;
}
.grid-spacing-10{
  margin:0px 10px 0px 10px;
}
.grid-spacing-10:first-child{
  margin-left:0px;
}
.grid-spacing-10:last-child{
  margin-right:0px;
}
.primary-link{
  color:var(--ui-primary);
  cursor: pointer;
}
/* text and titles */

/* material UI changes  */
.fullWidthIbssSwitchLabel label,.card-userPref label,.flex-search-filter-criteria label{
  width: 100%;
  margin-left: 0px;
  justify-content: space-between;
}
.ibss-vertical-tab{
  padding: 20px 10px;
  margin-left:14px;
}
.ibss-vertical-tab button{
  min-height: 42px !important;
  margin-bottom:15px !important;
  white-space: nowrap;
}
.card-userPref{
  width:508px; 
  padding: 43px; 
  font-family: "Source Sans Pro"; 
  background-color: var(--ui-background-alternate) !important;
}
.btn-right{
  display: flex; 
  justify-content: end; 
  margin-top: 35px;
}
.card-userPref-title{
  font-family: 'Source Sans Pro';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--ui-text);
} 
.card-userPref-text{
  font-family: 'Source Sans Pro';
  font-style: normal;
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 18px;
  display: flex;
  /* align-items: center; */
  color: var(--ui-text-alternate);
}
.text-info-xs{
  font-family: 'Source Sans Pro';
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  /* identical to box height, or 183% */
  display: flex !important;
  align-items: center !important;
  letter-spacing: 0.69px !important;
  text-transform: uppercase !important;
  /* Light/Primary */
  color: var(--ui-primary) !important;
}
.search-result-active{
  background: var(--ui-primary-pastel);
  border: 2px solid var(--ui-primary);
  border-radius: 8px;
}
.btn-radius{
  border-radius: 30px !important; 
  padding:0px 20px !important;
}
.ibss-timepicker{
  background: var(--ui-background-alternate);
  border: 1px solid var(--ui-mid-tone ) !important;
  border-radius: 4px;
  /* height:40px !important; */
  margin-top:16px !important;
  margin-bottom:16px !important;
}

/* Space analytics UI changes */
.result-item-center{
  margin-left:10px;
  margin-right: 10px;
  min-width:158px;
}
.text-ui-md{
    font-family: 'Source Sans Pro' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 23px !important;
    display: flex !important;
    align-items: center !important;
    color: var(--ui-text);
    text-align: left;
  }
  .text-ui-20{
    /* font-family: 'Inter'; */
    font-style: normal;
    /* font-weight: 400; */
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--ui-text);
  }
  .text-ui-14{
    /* font-family: 'Inter'; */
    font-style: normal;
    /* font-weight: 400; */
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: var(--ui-text);
  }
  .grid-row{
    padding:18px 12px;
    border-bottom: 1px solid var(--ui-mid-tone);
  }
.error-text{
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 18px;
  color: var(--ui-error);
  font-weight: bold;
}
.notification-label-lg{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: var(--ui-text);
}
.text-light-md{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ui-text-alternate);
}
/* Notification UI changes */
.notification-section{
    border-radius: 8px !important;
    width: 100%;
}

.equpmentDetails{
  background: #FFFFFF;
  border-radius: 7px;
  padding: 0px 30px;
}


.notification-box{
  height: calc(100vh - 114px) !important;
}
.notification-grid-image{
  display: flex;
  align-items: center;
  height: 100px;
  width:100px;
}

.notification-grid-image img{
    object-fit: cover;
    height: 100px;
    width:100px;
    border-radius: 8px;
}
.notification-label{
  font-family: 'Roboto';
font-style: normal;
/* font-weight: 500; */
font-size: 14px;
line-height: 24px;
letter-spacing: 0.4px;
text-transform: uppercase;
padding:8px 16px;
color: var(--ui-text-alternate);
}
.notification-right-pannel{
  height: calc(100vh - 114px) !important;
  background-color: var(--ui-background-alternate) !important;
  width: 523px !important;
  overflow-y: auto !important;
  margin-left: 32px !important;
}

.equipment-right-pannel{
  height: calc(100vh - 114px) !important;
  background-color: var(--ui-background-alternate) !important;
  width: 425px !important;
  overflow-y: auto !important;
  margin-left: 32px !important;

}
.v-scroll-notification{
  height: calc(100vh - 266px);
  overflow-y: auto;
}
.height-auto{
  height: auto !important;
}
.mx-7{
  padding-top:7px !important;
  padding-bottom:7px !important;
}
.btn-secondary-outline{
  background-color: var(--ui-background-alternate) !important;
  border: 1px solid var(--ui-mid-tone) !important;
  color: var(--ui-text) !important;
}
.notification-details-img{
    width: 100%;
    object-fit: cover;
    height: 250px;
}
.notification-details-card{
  padding: 32px 30px 25px 30px;
}
.notification-placeholder{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 270px);
}
.notification-circle-blue {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: var(--ui-primary);
}
.d-flex-relative{
  position: relative;
    display: flex;
    flex-flow: column;
}
.border-bottom-1{
  border-bottom: 1px solid var(--ui-mid-tone);
}
.space-analytics-grid{
  background-color:var(--ui-background-alternate);
  border-radius:8px 
}

/* browser compatibility issue */
  input.e-input:-moz-placeholder {
    -webkit-user-select: none !important;
    user-select: none !important;
}
.toast {
  -webkit-backdrop-filter: blur(10px) !important;
  -moz-backdrop-filter: blur(10px) !important;
  -ms-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
}

/* Not working */
.e-colorpicker.e-modal,
.e-colorpicker.e-hsv-model {
    -webkit-overflow: touch !important;
    overflow: touch !important;
    
}
.jodit-filebrowser-preview {
  min-height: auto !important;
  -moz-min-height:auto !important;
}
.display-block{
  display:block !important;
}
.right-pannel-sm{
  background-color: #ffffff;
  min-height: calc(100% - 32px);
  padding:16px 25px;
  border-radius: 7px;
}
.summery-card{
  background: var(--ui-background);
  border-radius: 7px;
  padding:16px 12px;
}
.summary-card-heading{
  font-family: 'Source Sans Pro' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 22px !important;
  margin-bottom:10px !important;
  line-height: 105.5% !important;
  display: flex;
  align-items: center;
  color: var(--ui-text);
}
.summary-card-heading-lg{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: var(--ui-background-topbar);
}
.font-18{
  font-size:18px !important;
}
.font-14{
  font-size:14px !important;
  color: var(--ui-text-alternate);
}
.icon-sm{
  /* font-size: 20px !important; */
  height: 20px !important;
  width: 20px !important;

}
.box-shadow-none{
  box-shadow: none !important;
}
.mr-20{
  margin-right: 20px !important;
}
.ibss-card{
    background: var(--ui-background-alternate);
    box-shadow: 0px 2px 7px rgba(38, 50, 56, 0.1);
    border-radius: 10px;
}
.ibss-card-header{
  padding:16px;
  border-bottom: 1px solid var(--ui-mid-tone);
}
.ibss-card-header .chart-panel-heading{
  display: flex;
  justify-content: start;
  flex-direction: row-reverse;
}
.ibss-card-header .chart-panel-header{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  font-size:16px !important;
  color: var(--ui-text);
}
.ibss-card-header .chart-panel-description{
  display: none;
}
.ibss-card-header .chart-panel-info{
  margin-bottom: 0px;
}
.ibss-card-header .chart-panel-heading .chart-panel-icon{
  margin-top:4px;
}
.ibss-card-header .chart-panel-heading .chart-panel-icon img{
  height: 18px;
  width: 18px;
  margin-left:8px;
  margin-bottom:0px
}
.ibss-card-body{
  padding:16px;
}
.barchart-data-labels{
  font-family: 'Source Sans Pro';
  font-style: normal;
  /* font-weight: 400; */
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  color: var(--ui-text);
}
.grid-header-fix{
  position:fixed;
  overflow: hidden;
  width: calc(100vw - 306px) !important;
  z-index: 99;
  background-color:var(--ui-background-alternate);
  margin-top: -8px !important;
  margin-left: -8px !important;
  border-radius: 8px 8px 0px 0px;
}

.text-link{
  color: var(--ui-primary);
  text-decoration:underline;
/* Close Button */
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}
.close {
  float: right;
  font-size: 2.5rem;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor:pointer;
}
.overflow-ellipses{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Map-Modal popup in flex/space-card/map/ ckick on grin area in map */
#map-modal .MuiDialogContent-root{
  padding: 0px;
}
#map-modal .MuiDialogContent-root .space-card{
  padding: 0px;
}
/* multicheck height on roles details  */
.multicheck-height{
  max-height: calc(100vh - 520px);
    overflow: hidden;
    overflow-y: auto;
}
.border-none{
  border:none;
}
.border-none:hover{
  border: none!important;
  background-color: transparent!important;
}
.heading-style
{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300!important;
    font-size: 30px;
    line-height: 38px;
};

.text-style{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
};
.page-height-exct-header {
  overflow: auto;
  height: calc(100vh - 82px);
}
.heatmap-height{
  height: calc(100vh - 220px);
}