.header {
  width:100%;
  min-height: 72px;
  background: var(--ui-background-alternate);
  color: var(--ui-text);
  z-index:100;

  .nav-list {
    list-style: none;
    font-family: Source Sans Pro;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px 30px;
    li {
      display: list-item;
    }
    .dropdown {
      float: left;
      overflow: hidden;
      z-index: 999;
      .dropbtn {
        cursor: pointer;
        height:40px;
        font-size: 16px;
        border: none;
        outline: none;
        color: var(--ui-text);
        padding: 9px 15px;
        background-color: inherit;
        font-family: Source Sans Pro;
        margin: 0;
        border: 1px solid var(--ui-mid-tone);
        border-radius: 10px;
      }
    }
    .usericon {
      width: 34px;
      height: 34px;
      margin: 0 22px 0 4px;
    }
    .dropdown-content {
      position: absolute;
      background: var(--ui-background-alternate);
      width: 257px;
      box-shadow: 0px 2px 7px var(--shadow);
      z-index: 21;
      top: 77px;
      right: 1.5rem;
      .switchTheme {
        float: none;
        color: var(--ui-text);
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
        cursor: pointer;
      }
      a {
        float: none;
        color: var(--ui-text);
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
    }
  }
}
.circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  border-color: var(--ui-text);
  vertical-align: middle;
}
.circle-quarter {
    background-image:
    linear-gradient(90deg, var(--ui-background-alternate) 50%, transparent 50%),
    linear-gradient(-180deg, var(--ui-text) 50%, transparent 50%);
  }
 .circle-half {
    background-image:
    linear-gradient(90deg, var(--ui-background-alternate) 50%, transparent 50%),
    linear-gradient(-90deg, var(--ui-text) 50%, transparent 50%);
  }
  .circle-three-quarter {
    background-image:
    linear-gradient(-180deg, transparent 50%, var(--ui-text) 50%),
    linear-gradient(270deg, var(--ui-text) 50%, transparent 50%)
   
  }
  .circle-full {
    background: var(--ui-text)
  }
  .px-8{
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
.notification-circle{
  height: 12px;
  width: 12px;
  position: absolute;
  margin-left: 13px;
  margin-bottom: 17px;
  border-radius: 50%;
  background-color: var(--ui-error);
}
.relative{
  position:relative;
}
.circle-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.circle-none-icon {
  background-image: url("../../assets/images/securityRight/None.png");
  }
.circle-quarter-icon {
  background-image: url("../../assets/images/securityRight/Basic.png");
  }
 .circle-half-icon {
  background-image: url("../../assets/images/securityRight/Local.png");
  }
  .circle-three-quarter-icon {
    background-image: url("../../assets/images/securityRight/Deep.png");
  }
  .circle-full-icon {
    background-image: url("../../assets/images/securityRight/Global.png");
  }

  .mt-50{
     margin-top:50px;
  }
  .mx-2{
     margin-top:20px;
     margin-bottom:20px;
  }
  .p-0{
     padding:0;
  }

