.side-navigation {
  width: 240px;
  height: 100vh;
  background: var(--ui-background-alternate);
  transition: width 300ms;
  .side-navigation-menu {
    position: relative;
    list-style: none;
    padding: 0;
    height: calc(100% - 129px);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0px;
    ul {
      list-style: none;
    }
    li {
      list-style: none;
      color: var(--ui-text);
      font-weight: bolder;
      line-height: 65px;
      a {
        text-decoration: none;
        font-size: 14px;
        // font-weight: 600;
        font-style:uiParagraphSemibold;
        color: var(--ui-text);
        vertical-align: middle;
      }
      img {
        height: 34px;
        // margin: 0 22px;
        vertical-align: middle;
      }
    }
    .non-active {
      color:var(--ui-text) !important;
    }
    .active-span{
      color: var(--ui-primary);
      margin-left: 14px;
      // font-weight: 600;
    }
    .non-active-span {
      color:var(--ui-text) !important;
      margin-left: 14px;
    }
    .menu-header-text{
      margin-left: 17px;
      font-weight: bolder;
      font-size: 16px;         
      }
    .menuI {
      font-size: 24px;
      float: right;
      padding-top: 24px;
      cursor: pointer;
      padding-right: 15px;
    }
  }
  .menu-header {
    border-bottom: 1px solid var(--ui-mid-tone);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-app-menu {
    cursor: pointer;
  }
  
  .menu-item li {
    height: 48px;
    line-height: 48px;
    border-top: 1px solid var(--ui-mid-tone);
    margin-left: -40px;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .side-navigation-list {
    list-style: none;
    padding: 0;
    margin: 50px 0;
    height: calc(100% - 255px);
    overflow-x: hidden;
    overflow-y: auto;
    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    li {
      height: 65px;
      line-height: 65px;
      display: flex;
      text-align: center;
      a {
        text-decoration: none;
        font-size: 16px;
        // font-weight: 600;
        font-style:uiParagraphSemibold;
        color: var(--ui-text-alternate);
        vertical-align: middle;
      }
      img {
        height: 34px;
        margin: 0 22px;
        vertical-align: middle;
      }
    }
    .active {
      color: var(--ui-primary);  // #4893cf
      // font-weight: 600;
      border-left: 3px solid var(--ui-accent) !important;
    }
    .non-active {
      border-left: 3px solid var(--ui-background-alternate) !important;
      color:var(--ui-text) !important;
    }
    .active-span{
      color: var(--ui-primary);
      // font-weight: 600;
    }
    .non-active-span {
      color:var(--ui-text) !important;
    }
  }
  .side-navigation-logo {
    background-color: var(--ui-mid-tone);
    height: 72px;
    overflow:hidden;
    // top: 0px;
    cursor: pointer;
    .companyLogo {
      width: 112px;
    }
    img{
      width: 232px;
    }
  }
  .sideMenuOptions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 225px;
    border: 1px solid var(--ui-text);
    margin-bottom: 54px;
    color: var(--ui-text);
    border-radius: 4px;
    background: var(--ui-background-alternate);
    ul {
      list-style: none;
      padding-left: 0px;
      margin: 0;
      a{
        color: var(--ui-text);
        text-decoration: none;
      }
      li {
        height: 50px;
        line-height: 50px;
        padding-left: 17px;
        border-bottom: 1px solid var(--ui-line-brake);
      }
    }
  }
}

.side-navigation-bottom-logo {
  border-top: 1px solid var(--ui-mid-tone);
  height: 56px;
  box-sizing: border-box;
}

.app-selector-menu {
  margin-left: 10px;
  font-size: 14px;
  font-weight: bolder;
}

.side-menu-selector {
  padding-left: 17px; 
  font-size: 14px;
  height: 129px;
  background-color: var(--ui-mid-tone);
  margin: 0px;
}

.selector-label {
  line-height: 14px !important;
  padding-top: 25px !important;
  font-size: 16px;
  font-weight: bold;
}

.selector-building-box {
    height: 43px !important;
    width: 191px !important;
    line-height: 43px !important;
    background-color: var(--ui-background-alternate);
    margin-top: 21px !important;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    span {
      padding-left: 13px !important;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 14px;
      a {
        color: var(--ui-primary) !important;
      }
    }

    div {
      display: flex;
      align-items: center;
    }
}
