.pageTitle {
    font-family: Source Sans Pro;
    font-size: 26px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--ui-text);
}

.columnHeading {
    font-family: Source Sans Pro;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
}

.columnSubheading {
    font-family: Source Sans Pro;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

}

.noResultsHeading {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.noResultsSubText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
}

.alternativeSpace {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
}